<app-navbar></app-navbar>

<!-- Modal de consentement pour les cookies -->
<!--<div class="modal fade" id="cookieConsentModal" tabindex="-1" aria-labelledby="cookieConsentLabel" aria-hidden="true">-->
<!--    <div class="modal-dialog">-->
<!--        <div class="modal-content">-->
<!--            <div class="modal-header">-->
<!--                <h5 class="modal-title" id="cookieConsentLabel">Gestion des cookies</h5>-->
<!--            </div>-->
<!--            <div class="modal-body">-->
<!--                Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant à utiliser ce site, vous acceptez notre utilisation des cookies.-->
<!--            </div>-->
<!--            <div class="modal-footer">-->
<!--                <button type="button" class="btn btn-secondary" (click)="declineCookies()">Refuser</button>-->
<!--                <button type="button" class="btn btn-primary" (click)="acceptCookies()">Accepter</button>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->

<router-outlet></router-outlet>
<app-footer></app-footer>
