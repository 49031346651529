<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
<!--                    <img src="assets/img/Bordeaux & blanc.png" width="300px" alt="about">-->
                    <br/><br/><br/>
<!--                    <h4>Votre partenaire de confiance</h4>-->
                    <h1>Votre partenaire <span>privilégié</span> pour une maintenance industrielle réfléchie</h1>
<!--                    <p>Une société crée par des professionnels et expert du domaine en remettant l'intérêt client au coeur de notre stratégie</p>-->
                    <a (click)="onClick('about')" class="btn btn-primary">Nous découvrir</a>
<!--                    <a (click)="onClick('work')" class="btn btn-primary view-work">Notre travail</a>-->
                </div>
            </div>
        </div>
    </div>
</div>

<app-cta></app-cta>

<!--Bloc bienvenue chez teneo, pres succinte-->
<app-welcome></app-welcome>

<!--Bloc description de l'entreprise-->
<app-about></app-about>

<!--Bloc description maintenance industrielle image à gauche-->
<app-strategy></app-strategy>

<!--Bloc bureau d'études image à droite-->
<app-strategy2></app-strategy2>

<!--Bloc description maintenance industrielle image à gauche-->
<app-strategy3></app-strategy3>

<app-who-we-are></app-who-we-are>

<!--<app-cta></app-cta>-->

<!--<app-skill></app-skill>-->

<!--<app-funfacts></app-funfacts>-->

<app-team></app-team>

<!--<app-how-we-work></app-how-we-work>-->

<!--<app-services></app-services>-->

<!--<app-why-we-different></app-why-we-different>-->

<!--<app-work></app-work>-->

<!--<app-pricing></app-pricing>-->

<!--<app-faq></app-faq>-->

<!--<app-feedback></app-feedback>-->

<!--<app-blog></app-blog>-->

<!--<app-partner></app-partner>-->

<!--<app-subscribe></app-subscribe>-->

<app-contact></app-contact>
