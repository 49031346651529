<div class="page-title-area-mentions item-bg-maint2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1 style="color: white">Politique de confidentialité</h1>
                    <p style="color: white">Version mise à jour le 22.02.2025</p>
                    <br>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Politique de confidentialité</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-text">

                        <div id="cmplz-document" class="cmplz-document cookie-statement cmplz-document-eu"><p><i>Cette
                            politique de cookies a été mise à jour pour la dernière fois le 17 février 2024 et
                            s’applique aux citoyens et aux résidents permanents légaux de l’Espace Économique Européen
                            et de la Suisse.</i><br></p>
                            <h2>1. Introduction</h2>
                            <p>Notre site web, <a href="https://www.a2p-avocat.eu">https://www.a2p-avocat.eu</a>
                                (ci-après&nbsp;: «&nbsp;le site web&nbsp;») utilise des cookies et autres technologies
                                liées (par simplification, toutes ces technologies sont désignées par le terme «&nbsp;cookies&nbsp;»).
                                Des cookies sont également placés par des tierces parties que nous avons engagées. Dans
                                le document ci-dessous, nous vous informons de l’utilisation des cookies sur notre site
                                web.</p>
                            <h2>2. Que sont les cookies&nbsp;?</h2>
                            <p>Un cookie est un petit fichier simple envoyé avec les pages de ce site web et stocké par
                                votre navigateur sur le disque dur de votre ordinateur ou d’un autre appareil. Les
                                informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs
                                des tierces parties concernées lors d’une visite ultérieure.</p>
                            <h2>3. Que sont les scripts&nbsp;?</h2>
                            <p>Un script est un élément de code utilisé pour que notre site web fonctionne correctement
                                et de manière interactive. Ce code est exécuté sur notre serveur ou sur votre
                                appareil.</p>
                            <h2>4. Qu’est-ce qu’une balise invisible&nbsp;?</h2>
                            <p>Une balise invisible (ou balise web) est un petit morceau de texte ou d’image invisible
                                sur un site web, utilisé pour suivre le trafic sur un site web. Pour ce faire, diverses
                                données vous concernant sont stockées à l’aide de balises invisibles.</p>
                            <h2>5. Cookies</h2>
                            <p class="cmplz-subtitle">5.1 Cookies techniques ou fonctionnels</p>
                            <p>Certains cookies assurent le fonctionnement correct de certaines parties du site web et
                                la prise en compte de vos préférences en tant qu’internaute. En plaçant des cookies
                                fonctionnels, nous vous facilitons la visite de notre site web. Ainsi, vous n’avez pas
                                besoin de saisir à plusieurs reprises les mêmes informations lors de la visite de notre
                                site web et, par exemple, les éléments restent dans votre panier jusqu’à votre paiement.
                                Nous pouvons déposer ces cookies sans votre consentement.</p>
                            <p class="cmplz-subtitle">5.2 Cookies de marketing/suivi</p>
                            <p>Les cookies de marketing/suivi sont des cookies ou toute autre forme de stockage local,
                                utilisés pour créer des profils d’utilisateurs afin d’afficher de la publicité ou de
                                suivre l’utilisateur sur ce site web ou sur plusieurs sites web dans des finalités
                                marketing similaires.</p>
                            <h2>6. Cookies placés</h2>
                            <div id="cmplz-cookies-overview">
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Elementor</h3>
                                            <p>Statistiques (anonymes)</p>
                                            <label for="cmplz_service_elementor"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service elementor</span></label>
                                            <input type="checkbox" id="cmplz_service_elementor"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="elementor"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons Elementor pour la création de contenu. <a target="_blank"
                                                                                                    rel="noopener noreferrer nofollow"
                                                                                                    href="https://cookiedatabase.org/service/elementor/">Lire
                                            la suite</a></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Ces données ne sont pas partagées avec des tierces parties.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Statistiques (anonymes)</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/elementor/elementor/">elementor</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">persistante</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les actions effectuées par les utilisateurs sur le
                                            site web
                                        </div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>WordPress</h3>
                                            <p>Fonctionnel</p>
                                            <label for="cmplz_service_wordpress"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service wordpress</span></label>
                                            <input type="checkbox" id="cmplz_service_wordpress"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="wordpress"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons WordPress pour le développement du site web. <a
                                            target="_blank" rel="noopener noreferrer nofollow"
                                            href="https://cookiedatabase.org/service/wordpress/">Lire la suite</a></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Ces données ne sont pas partagées avec des tierces parties.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Fonctionnel</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/wordpress/wordpress_test_cookie/">wordpress_test_cookie</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">aucune</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Vérifier si des cookies peuvent être déposés</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/wordpress/wordpress_logged_in_/">wordpress_logged_in_*</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">persistante</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Garder les utilisateurs connectés</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/wordpress/wp_lang/">wp_lang</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">session</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les réglages de langue</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/wordpress/wpemojisettingssupports/">wpEmojiSettingsSupports</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">session</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les informations du navigateur</div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Complianz</h3>
                                            <p>Fonctionnel</p>
                                            <label for="cmplz_service_complianz"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service complianz</span></label>
                                            <input type="checkbox" id="cmplz_service_complianz"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="complianz"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons Complianz pour la gestion du consentement aux cookies. <a
                                            target="_blank" rel="noopener noreferrer nofollow"
                                            href="https://cookiedatabase.org/service/complianz/">Lire la suite</a></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Ces données ne sont pas partagées avec des tierces parties.&nbsp;Pour plus
                                            d’informations, veuillez lire la <a target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                href="https://complianz.io/legal/privacy-statement/">déclaration
                                                de confidentialité Complianz</a>.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Fonctionnel</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/complianz_policy_id/">complianz_policy_id</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker l’ID de la politique de cookies acceptée</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_marketing/">cmplz_marketing</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les préférences de consentement aux cookies</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/complianz_consent_status/">complianz_consent_status</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les préférences de consentement aux cookies</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_consented_services/">cmplz_consented_services</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les préférences de consentement aux cookies</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_policy_id/">cmplz_policy_id</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker l’ID de la politique de cookies acceptée</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_statistics/">cmplz_statistics</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les préférences de consentement aux cookies</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_preferences/">cmplz_preferences</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les préférences de consentement aux cookies</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_functional/">cmplz_functional</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les préférences de consentement aux cookies</div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/complianz/cmplz_banner-status/">cmplz_banner-status</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">365 jours</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Mémorise si la bannière des cookies a été refusée</div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Wordfence</h3>
                                            <p>Fonctionnel</p>
                                            <label for="cmplz_service_wordfence"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service wordfence</span></label>
                                            <input type="checkbox" id="cmplz_service_wordfence"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="wordfence"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons Wordfence pour la sécurité du site web. <a target="_blank"
                                                                                                     rel="noopener noreferrer nofollow"
                                                                                                     href="https://cookiedatabase.org/service/wordfence/">Lire
                                            la suite</a></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Pour plus d’informations, veuillez lire la <a target="_blank"
                                                                                         rel="noopener noreferrer nofollow"
                                                                                         href="https://www.wordfence.com/privacy-policy/">déclaration
                                            de confidentialité Wordfence</a>.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Fonctionnel</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/wordfence/wfwaf-authcookie/">wfwaf-authcookie*</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">1 jour</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Déterminer si l'utilisateur est connecté</div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Google Fonts</h3>
                                            <p>Marketing</p>
                                            <label for="cmplz_service_google-fonts"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service google-fonts</span></label>
                                            <input type="checkbox" id="cmplz_service_google-fonts"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="google-fonts"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons Google Fonts pour l’affichage des polices web. <a
                                            target="_blank" rel="noopener noreferrer nofollow"
                                            href="https://cookiedatabase.org/service/google-fonts/">Lire la suite</a>
                                        </p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Pour plus d’informations, veuillez lire la <a target="_blank"
                                                                                         rel="noopener noreferrer nofollow"
                                                                                         href="https://policies.google.com/privacy">déclaration
                                            de confidentialité Google Fonts</a>.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Marketing</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/google-fonts/tcb_google_fonts/">Google
                                            Fonts API</a></div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">aucune</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Demander l’adresse IP de l’utilisateur</div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Google Maps</h3>
                                            <p>Marketing</p>
                                            <label for="cmplz_service_google-maps"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service google-maps</span></label>
                                            <input type="checkbox" id="cmplz_service_google-maps"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="google-maps"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons Google Maps pour l’affichage de cartes. <a target="_blank"
                                                                                                     rel="noopener noreferrer nofollow"
                                                                                                     href="https://cookiedatabase.org/service/google-maps/">Lire
                                            la suite</a></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Pour plus d’informations, veuillez lire la <a target="_blank"
                                                                                         rel="noopener noreferrer nofollow"
                                                                                         href="https://policies.google.com/privacy">déclaration
                                            de confidentialité Google Maps</a>.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Marketing</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/google-maps/google-maps-api/">Google
                                            Maps API</a></div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">aucune</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Demander l’adresse IP de l’utilisateur</div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Polylang</h3>
                                            <p>Fonctionnel</p>
                                            <label for="cmplz_service_polylang" class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service polylang</span></label>
                                            <input type="checkbox" id="cmplz_service_polylang"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="polylang"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p>Nous utilisons Polylang pour la gestion des paramètres régionaux. <a
                                            target="_blank" rel="noopener noreferrer nofollow"
                                            href="https://cookiedatabase.org/service/polylang/">Lire la suite</a></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Ces données ne sont pas partagées avec des tierces parties.</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Fonctionnel</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name"><a target="_blank" rel="noopener noreferrer nofollow"
                                                             href="https://cookiedatabase.org/cookie/polylang/pll_language/">pll_language</a>
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention">persistante</div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function">Stocker les réglages de langue</div>

                                    </div>
                                </details>
                                <details class="cmplz-dropdown cmplz-service-desc cmplz-dropdown-cookiepolicy ">
                                    <summary class="cmplz-service-header">
                                        <div>
                                            <h3>Divers</h3>
                                            <p>Finalité en attente d’enquête</p>
                                            <label for="cmplz_service_divers"
                                                   class="cmplz_consent_per_service_label"><span
                                                class="screen-reader-text">Consent to service divers</span></label>
                                            <input type="checkbox" id="cmplz_service_divers"
                                                   class="cmplz-accept-service cmplz-hidden" data-service="divers"
                                                   data-category="functional"></div>
                                    </summary>
                                    <div class="cmplz-service-description">
                                        <h4>Utilisation</h4>
                                        <p></p>
                                    </div>
                                    <div class="cmplz-sharing-data">
                                        <h4>Partage de données</h4>
                                        <p>Le partage des données est en cours d’enquête</p>
                                    </div>
                                    <div class="cookies-per-purpose">
                                        <div class="purpose"><h4>Finalité en attente d’enquête</h4></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">o2s-chl</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">404page_current_tab</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">elementor_safe_mode_token</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">e_kit-elements-defaults</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">ea-form-options</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">eael_screen</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">persist:hs-beacon-2496aba6-0292-489c-8f5d-1c0fba417c2f</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">
                                            persist:hs-beacon-message-2496aba6-0292-489c-8f5d-1c0fba417c2f
                                        </div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">mtnc_upsell_shown_timestamp</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">mtnc_upsell_shown</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">klarna_amazon_modal_closed</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">e_library</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>
                                        <div class="name-header">
                                            <h5>Nom</h5>
                                        </div>
                                        <div class="name">sessionId</div>
                                        <div class="retention-header">
                                            <h5>Expiration</h5>
                                        </div>
                                        <div class="retention"></div>
                                        <div class="function-header">
                                            <h5>Fonction</h5>
                                        </div>
                                        <div class="function"></div>

                                    </div>
                                </details>
                            </div>
                            <h2>7. Consentement</h2>
                            <p>Lorsque vous visitez notre site web pour la première fois, nous vous montrerons une
                                fenêtre contextuelle avec une explication sur les cookies. Dès que vous cliquez sur «&nbsp;Enregistrer
                                les préférences&nbsp;» vous nous autorisez à utiliser les catégories de cookies et
                                d’extensions que vous avez sélectionnés dans la fenêtre contextuelle, comme décrit dans
                                la présente politique de cookies. Vous pouvez désactiver l’utilisation des cookies via
                                votre navigateur, mais veuillez noter que notre site web pourrait ne plus fonctionner
                                correctement.</p>
                            <p class="cmplz-subtitle">7.1 Gérez vos réglages de consentement</p>
                            <div id="cmplz-manage-consent-container-nojavascript" style="display: none;">Vous avez
                                chargé la politique de cookies sans le support de javascript.&nbsp;Sur AMP, vous pouvez
                                utiliser l’onglet de gestion du consentement en bas de la page.
                            </div>
                            <div id="cmplz-manage-consent-container" class="cmplz-manage-consent-container"
                                 style="display: block;"><!-- categories start -->
                                <div class="cmplz-categories">
                                    <details class="cmplz-category cmplz-functional">
                                        <summary>
						<span class="cmplz-category-header">
							<span class="cmplz-category-title">Cookies fonctionnels</span>
							<span class="cmplz-always-active">
								<span class="cmplz-banner-checkbox">
									<input type="checkbox" id="cmplz-functional-optin" data-category="cmplz_functional"
                                           class="cmplz-consent-checkbox cmplz-functional" size="40" value="1">
									<label class="cmplz-label" for="cmplz-functional-optin" tabindex="0"><span
                                        class="screen-reader-text">Cookies fonctionnels</span></label>
								</span>
								Toujours activé							</span>
							<span class="cmplz-icon cmplz-open">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="18"><path
                                    d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg>
							</span>
						</span>
                                        </summary>
                                        <div class="cmplz-description">
                                            <span class="cmplz-description-functional">Le stockage ou l’accès technique est strictement nécessaire dans la finalité d’intérêt légitime de permettre l’utilisation d’un service spécifique explicitement demandé par l’abonné ou l’utilisateur, ou dans le seul but d’effectuer la transmission d’une communication sur un réseau de communications électroniques.</span>
                                        </div>
                                    </details>

                                    <details class="cmplz-category cmplz-preferences">
                                        <summary>
						<span class="cmplz-category-header">
							<span class="cmplz-category-title">Préférences</span>
							<span class="cmplz-banner-checkbox">
								<input type="checkbox" id="cmplz-preferences-optin" data-category="cmplz_preferences"
                                       class="cmplz-consent-checkbox cmplz-preferences" size="40" value="1">
								<label class="cmplz-label" for="cmplz-preferences-optin" tabindex="0"><span
                                    class="screen-reader-text">Préférences</span></label>
							</span>
							<span class="cmplz-icon cmplz-open">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="18"><path
                                    d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg>
							</span>
						</span>
                                        </summary>
                                        <div class="cmplz-description">
                                            <span class="cmplz-description-preferences">Le stockage ou l’accès technique est nécessaire dans la finalité d’intérêt légitime de stocker des préférences qui ne sont pas demandées par l’abonné ou la personne utilisant le service.</span>
                                        </div>
                                    </details>

                                    <details class="cmplz-category cmplz-statistics">
                                        <summary>
						<span class="cmplz-category-header">
							<span class="cmplz-category-title">Statistiques</span>
							<span class="cmplz-banner-checkbox">
								<input type="checkbox" id="cmplz-statistics-optin" data-category="cmplz_statistics"
                                       class="cmplz-consent-checkbox cmplz-statistics" size="40" value="1">
								<label class="cmplz-label" for="cmplz-statistics-optin" tabindex="0"><span
                                    class="screen-reader-text">Statistiques</span></label>
							</span>
							<span class="cmplz-icon cmplz-open">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="18"><path
                                    d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg>
							</span>
						</span>
                                        </summary>
                                        <div class="cmplz-description">
                                            <span class="cmplz-description-statistics">Le stockage ou l’accès technique qui est utilisé exclusivement à des fins statistiques.</span>
                                            <span class="cmplz-description-statistics-anonymous">Le stockage ou l’accès technique qui est utilisé exclusivement dans des finalités statistiques anonymes. En l’absence d’une assignation à comparaître, d’une conformité volontaire de la part de votre fournisseur d’accès à internet ou d’enregistrements supplémentaires provenant d’une tierce partie, les informations stockées ou extraites à cette seule fin ne peuvent généralement pas être utilisées pour vous identifier.</span>
                                        </div>
                                    </details>
                                    <details class="cmplz-category cmplz-marketing">
                                        <summary>
						<span class="cmplz-category-header">
							<span class="cmplz-category-title">Marketing</span>
							<span class="cmplz-banner-checkbox">
								<input type="checkbox" id="cmplz-marketing-optin" data-category="cmplz_marketing"
                                       class="cmplz-consent-checkbox cmplz-marketing" size="40" value="1">
								<label class="cmplz-label" for="cmplz-marketing-optin" tabindex="0"><span
                                    class="screen-reader-text">Marketing</span></label>
							</span>
							<span class="cmplz-icon cmplz-open">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" height="18"><path
                                    d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"></path></svg>
							</span>
						</span>
                                        </summary>
                                        <div class="cmplz-description">
                                            <span class="cmplz-description-marketing">Le stockage ou l’accès technique est nécessaire pour créer des profils d’utilisateurs afin d’envoyer des publicités, ou pour suivre l’utilisateur sur un site web ou sur plusieurs sites web ayant des finalités marketing similaires.</span>
                                        </div>
                                    </details>
                                </div><!-- categories end --></div>
                            <h2>8. Activer/désactiver et supprimer les cookies</h2>
                            <p>Vous pouvez utiliser votre navigateur internet pour supprimer automatiquement ou
                                manuellement les cookies. Vous pouvez également spécifier que certains cookies ne
                                peuvent pas être placés. Une autre option consiste à modifier les réglages de votre
                                navigateur Internet afin que vous receviez un message à chaque fois qu’un cookie est
                                placé. Pour plus d’informations sur ces options, reportez-vous aux instructions de la
                                section Aide de votre navigateur.</p>
                            <p>Veuillez noter que notre site web peut ne pas marcher correctement si tous les cookies
                                sont désactivés. Si vous supprimez les cookies dans votre navigateur, ils seront de
                                nouveau placés après votre consentement lorsque vous revisiterez notre site web.</p>
                            <h2>9. Vos droits concernant les données personnelles</h2>
                            <p>Vous avez les droits suivants concernant vos données personnelles&nbsp;:</p>
                            <ul>
                                <li>Vous avez le droit de savoir pourquoi vos données personnelles sont nécessaires, ce
                                    qui leur arrivera et combien de temps elles seront conservées.
                                </li>
                                <li>Droit d’accès&nbsp;: vous avez le droit d’accéder à vos données personnelles que
                                    nous connaissons.
                                </li>
                                <li>Droit de rectification&nbsp;: vous avez le droit à tout moment de compléter,
                                    corriger, faire supprimer ou bloquer vos données personnelles.
                                </li>
                                <li>Si vous nous donnez votre consentement pour le traitement de vos données, vous avez
                                    le droit de révoquer ce consentement et de faire supprimer vos données personnelles.
                                </li>
                                <li>Droit de transférer vos données&nbsp;: vous avez le droit de demander toutes vos
                                    données personnelles au responsable du traitement et de les transférer dans leur
                                    intégralité à un autre responsable du traitement.
                                </li>
                                <li>Droit d’opposition&nbsp;: vous pouvez vous opposer au traitement de vos données.
                                    Nous obtempérerons, à moins que certaines raisons ne justifient ce traitement.
                                </li>
                            </ul>
                            <p>Pour exercer ces droits, veuillez nous contacter. Veuillez vous référer aux coordonnées
                                au bas de cette politique de cookies. Si vous avez une plainte concernant la façon dont
                                nous traitons vos données, nous aimerions en être informés, mais vous avez également le
                                droit de déposer une plainte auprès de l’autorité de contrôle (l’autorité chargée de la
                                protection des données).</p>
                            <h2>10. Coordonnées</h2>
                            <p>Pour des questions et/ou des commentaires sur notre politique de cookies et cette
                                déclaration, veuillez nous contacter en utilisant les coordonnées suivantes&nbsp;:</p>
                            <p><span class="cmplz-contact-organisation">Anne Pillias-Perron - Avocat à la Cour d'Appel de Paris - DPO &amp; Médiateur</span><br>
                                <span class="cmplz-contact-address">10, Allées des Champs-Élysées<br>
91000 Évry-Courcouronnes</span><br>
                                <span class="cmplz-contact-country">France</span><br>
                                Site web&nbsp;: <span class="cmplz-contact-domain"><a href="https://www.a2p-avocat.eu">https://www.a2p-avocat.eu</a></span><br>
<!--                                E-mail&nbsp;: <span class="cmplz-contact-email">rgpd@a2p-avocat.eu</span><br>-->
                                <span class="cmplz-contact-telephone">Numéro de téléphone : +33 (0)1 85 41 13 83</span>
                            </p>
                            <p>Cette politique de cookies a été synchronisée avec <a href="https://cookiedatabase.org/"
                                                                                     target="_blank">cookiedatabase.org</a>
                                le 17 février 2024.</p></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



