import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-rgpd',
  templateUrl: './rgpd.component.html',
  styleUrl: './rgpd.component.scss'
})
export class RgpdComponent implements OnInit {
    ngOnInit() {
    }
}
