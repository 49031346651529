<div class="page-title-area-mentions item-bg-maint2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1 style="color: white">Politique de confidentialité</h1>
                    <p style="color: white">Version mise à jour le 22.02.2025</p>
                    <br>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">Politique de confidentialité</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-text">

                        <!--                        <h2>2. Conditions générales d'utilisation du site et des services proposés</h2>-->
                        <!--                        <p>L’u                       régulière.</p>-->

                        <div class="elementor-widget-container">
                            <p><strong>Préambule</strong></p>
                            <p>
                                La présente Politique de confidentialité (<em>ci-après la «&nbsp;Politique&nbsp;»</em>)
                                s’adresse aux utilisateurs du site internet <a href="https://www.teneo-industrie.fr/">https://www.teneo-industrie.fr</a>
                                (c<em>i-après le « Site »).</em>
                            </p>
                            <p>
                                Le Site est édité par Jonathan Hellé – CEO de Ténéo Industrie &amp;
                                Le siège est situé 24 RUE DU BEL AIR 91540 MENNECY.
                            </p>
                            <p>Le respect de la vie privée et des données à caractère personnel est une priorité pour
                                Ténéo Industrie.</p>
                            <p>Cette Politique de confidentialité a pour objet de présenter la teneur des traitements de
                                données à caractère personnel mis en œuvre sur le Site.</p>
                            <p>En tout état de cause, Ténéo Industrie s’engage à respecter les trois principes essentiels
                                suivants :</p>
                            <ul>
                                <li>Ne collecter que les données strictement nécessaires au regard de leur finalité ;
                                </li>
                                <li>L’utilisateur reste maître de ses données à caractère personnel ;</li>
                                <li>Les données à caractère personnel des utilisateurs sont traitées de manière
                                    transparente, confidentielle et sécurisée.
                                </li>
                            </ul>
                            <p>
                                En utilisant le Site, vous pouvez être amené à communiquer à Ténéo Industrie vos données à
                                caractère personnel, et notamment par l’intermédiaire du formulaire de contact.
                            </p>
                            <p>
                                Les traitements de données à caractère personnel sont régis par :
                            </p>
                            <ul>
                                <li>La loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux
                                    libertés modifiée ;
                                </li>
                            </ul>
                            <ul>
                                <li>Le Règlement (UE) 2016/679 du parlement européen et du conseil du 27 avril 2016,
                                    entré en application le 25 mai 2018 (<em>ci-après le « RGPD »</em>).
                                </li>
                            </ul>
                            <p>
                                La notion de « données à caractère personnel » ou « données personnelles » est définie à
                                l’article 4 du RGPD, à savoir toute information susceptible de permettre
                                l’identification d’une personne physique de manière directe ou indirecte : nom, prénom,
                                adresse IP, adresse courriel, etc.
                            </p>
                            <p>
                                Ténéo Industrie est susceptible de modifier sa Politique de confidentialité. Le cas échéant,
                                il vous en informera en modifiant la date située en haut de la présente Politique et,
                                dans certains cas, il procédera à des notifications complémentaires (en ajoutant, par
                                exemple, une mention sur la page d’accueil du Site ou en vous envoyant un courriel).
                                Ténéo Industrie vous invite à consulter sa Politique de confidentialité dès que vous
                                interagissez avec lui pour rester informés des pratiques de Ténéo Industrie en matière de
                                protection des données à caractère personnel, et, ainsi, avoir connaissance des méthodes
                                que vous pouvez utiliser pour contrôler l’usage de vos données personnelles et protéger
                                votre vie privée.
                            </p>
                            <p>
                                Par ailleurs, Ténéo Industrie pourra être amené à recueillir certaines informations relatives
                                à votre navigation sur le Site afin d’assurer le bon fonctionnement et l’amélioration
                                permanente du Site, de ses services et de ses fonctionnalités et de vous proposer une
                                expérience personnalisée sur le Site. Cette collecte est réalisée dans les conditions
                                décrites à la
                                <a href="https://www.teneo-industrie.fr/mentions">Mentions légales</a>
                                de Ténéo Industrie.
                            </p>
                            <p>Les utilisateurs «&nbsp;personnes morales&nbsp;» s’engagent à transmettre la présente
                                Politique à leurs salariés, représentants et/ou dirigeants dont les données à caractère
                                personnel pourraient être traitées par Ténéo Industrie dans le cadre de ses services.</p>
                            <p>Afin de s’assurer du respect de la réglementation en matière de protection des données
                                personnelles et protéger au mieux vos données, Ténéo Industrie a désigné un point de contact
                                en charge de la protection des données personnelles qui peut être contacté par email à
                                l’adresse électronique r g p d [ a t ] t e n e o - i n d u s t r i e . f r (sans
                                espaces).</p>
                            <p><strong>1. Définitions</strong></p>
                            <p>Les termes, mentionnés ci-dessous, ont, au sens de la présente Politique de
                                confidentialité, la signification suivante :</p>
                            <ul>
                                <li><strong>Utilisateur </strong>: désigne toute personne qui accède et navigue sur le
                                    Site en tant que simple internaute (prospect, contact) ;
                                </li>
                                <li><strong>Responsable de traitement </strong>: désigne l’entité qui détermine les
                                    moyens et finalités d’un traitement de données à caractère personnel ;
                                </li>
                                <li><strong>Services : </strong>désigne l’ensemble des prestations proposées par
                                    Ténéo Industrie qui sont disponibles sur le Site ;
                                </li>
                                <li><strong>Sous-traitant : </strong>désigne la personne traitant des données à
                                    caractère personnel pour le compte du Responsable de traitement, qui agit sous
                                    l’autorité du Responsable de traitement et sur instruction de celui-ci.
                                </li>
                            </ul>
                            <p>
                                <strong>2. Identité du responsable de traitement</strong>
                            </p>
                            <p>
                                Vos données à caractère personnel sont collectées et traitées par Jonathan Hellé –
                                Ceo de Ténéo Industrie &amp; Médiateur, dont le siège est situé 24 RUE DU BEL AIR 91540 MENNECY.
                                Ténéo Industrie est le responsable du traitement
                                des données collectées sur le Site.
                            </p>
                            <p>
                                Pour toute question relative à la gestion et à l’utilisation faites de vos données à
                                caractère personnel, vous pouvez contacter Ténéo Industrie à l’adresse électronique suivante
                                r g p d [ a t ] t e n e o - i n d u s t r i e . f r (sans espaces) ou par courrier à
                                Jonathan Hellé - Ceo de Ténéo Industrie &amp; 24 RUE DU BEL AIR 91540 MENNECY.
                            </p>
                            <p><strong>3. Collecte des données</strong></p>
                            <p>Ténéo Industriet collecte uniquement les données que vous fournissez quand vous avez recours à
                                ses services en ligne, et plus particulièrement lorsque vous :</p>
                            <ul>
                                <li>Naviguez ou consultez le Site ;</li>
                                <li>Remplissez le formulaire de contact&nbsp;;</li>
                                <li>Contactez directement Ténéo Industrie&nbsp;;</li>
                                <li>Adressez des courriers à Ténéo Industrie.</li>
                            </ul>
                            <p>Lors de toutes demandes effectuées par l’intermédiaire du Site, vous remplissez un
                                formulaire et communiquez différentes données à caractère personnel vous concernant pour
                                bénéficier de l’ensemble des services proposés par Ténéo Industrie. Lorsque cela est
                                nécessaire, au regard de la Loi Informatique et Libertés modifiée et/ou du RGPD, Ténéo Industrie
                                s’engage, selon les cas, à recueillir votre consentement et/ou à vous permettre
                                de vous opposer à l’utilisation de vos données pour certaines finalités.</p>
                            <p><strong>4. &nbsp;Nature des données collectées</strong></p>
                            <p>Lorsque les Utilisateurs naviguent sur le Site et/ou souhaitent bénéficier des services
                                disponibles sur le Site, Ténéo Industrie peut être amené à collecter certaines données
                                personnelles. Ces données sont traitées conformément aux finalités prévues lors de la
                                collecte.</p>
                            <p>Les données susceptibles d’être collectées et traitées par Ténéo Industrie pour la réalisation
                                des finalités décrites à l’article 5 de la présente Politique de confidentialité
                                concernent :</p>
                            <ul>
                                <li>Des données d’identification (nom, prénom, courriel, adresse,&nbsp;<span
                                    style="font-family: 'Open Sans', sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">adresse IP,&nbsp;</span>numéro
                                    de téléphone aux fins de preuve de
                                    l’exercice d’un droit d’accès, de rectification ou d’opposition ou pour répondre à
                                    une obligation légale) ainsi que les données personnelles éventuellement contenues
                                    dans le message de demande de renseignements
                                </li>
                                <li>Des données relatives au suivi de votre relation avec Ténéo Industrie : demandes
                                    d’informations, historique des échanges avec Ténéo Industrie&nbsp;;
                                </li>
                                <li>Des cookies (voir infra article 7. Cookies).</li>
                            </ul>
                            <p>Quel que soit le mode de collecte de vos données à caractère personnel, Ténéo Industrie
                                s’engage à vous informer des finalités du traitement, du caractère obligatoire ou
                                facultatif des réponses à apporter, des éventuelles conséquences, à son égard, d’un
                                défaut de réponse, des destinataires des données, de l’existence et des modalités
                                d’exercice de vos droits, dont les droits d’accès, de rectification et d’opposition au
                                traitement de vos données (voir infra Article 9. Droits).</p>
                            <p><strong>5. Finalités du traitement et base juridique</strong></p>
                            <p>Certaines de vos données sont susceptibles d’être collectées par Ténéo Industrie pour des
                                finalités explicites, légitimes et déterminées afin d’assurer principalement :</p>
                            <ul>
                                <li>La gestion du site internet de Ténéo Industrie en vue de l’optimisation de son
                                    fonctionnement et son amélioration permanente ainsi que celle de ses services et de
                                    ses fonctionnalités&nbsp;;
                                </li>
                                <li>La fourniture des services ou des informations que vous avez sollicités&nbsp;;</li>
                                <li>La réponse à vos demandes et/ou interrogations&nbsp;;</li>
                                <li>Les opérations de prospection&nbsp;commerciale ainsi que</li>
                                <li>La gestion des obligations imposées par le RGPD.</li>
                            </ul>
                            <p>Lors de la collecte des informations à caractère personnel, les informations essentielles
                                à la réalisation de ces finalités, et indispensables pour répondre à vos demandes, sont
                                indiquées au moyen d’un astérisque (principalement, nom, prénom et courriel). Dans le
                                cas où vous n’auriez pas renseigné tous les champs obligatoires, Ténéo Industrie ne serait
                                peut-être pas en mesure de répondre à l’intégralité de vos demandes.</p>
                            <p>En tout état de cause, et pour chaque finalité définie, Ténéo Industrie mettra tous les moyens
                                en sa possession pour assurer la sécurité, l’intégrité et la confidentialité des données
                                à caractère personnel qui lui sont confiées, dans le respect de la règlementation en
                                vigueur.</p>
                            <p>Ténéo Industrie utilise vos données à caractère personnel en fonction des finalités pour
                                lesquelles vous les lui avez transmises, lesquelles reposent sur l’un des fondements
                                juridiques suivants (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre2#Article6">article
                                    6 du RGPD</a>) :</p>
                            <ul>
                                <li><strong>Le contrat&nbsp;:</strong> pour la gestion du Site (CGU), ce qui inclut
                                    notamment son fonctionnement et son amélioration&nbsp;;
                                </li>
                                <li><strong>Votre consentement </strong>: pour les opérations de prospection commerciale
                                    par voie électronique ainsi que pour répondre à vos demandes &nbsp;;
                                </li>
                                <li><strong>Les intérêts légitimes de Ténéo Industrie </strong>: pour les opérations de
                                    prospection&nbsp;commerciale de Ténéo Industrie en vue du développement ce dernier et pour
                                    les invitations à des événements organisés par Ténéo Industrie ;
                                </li>
                                <li><strong>Le respect des obligations légales&nbsp;de Ténéo Industrie</strong> : <em>pour </em>la
                                    gestion des obligations imposées par le RGPD, dont la sécurité du Site.
                                </li>
                            </ul>
                            <p>
                                <strong>6. Destinataires des données</strong>
                            </p>
                            <p>
                                L’ensemble des données à caractère personnel recueillies sur le Site sont destinées à Ténéo Industrie.
                            </p>
                            <p>
                                Vos données personnelles peuvent également être transmises à des prestataires externes
                                qui les traitent pour le compte de Ténéo Industrie, selon les instructions de ce dernier, dans
                                les limites prévues par les dispositions en vigueur et conformément à la présente
                                Politique.
                            </p>
                            <p>
                                Il s’agit notamment du personnel dûment habilité des sociétés tierces fournissant des
                                services de support, des prestataires de services technologiques en ce qui concerne par
                                exemple la maintenance informatique.
                            </p>
                            <p>
                                Il s’agit en outre du personnel habilité des sous-traitants de Ténéo Industrie, à savoir
                                notamment, l’hébergeur du Site.
                            </p>
                            <p>
                                Dans ce cadre, et afin de réduire les risques de perte ou d’atteinte aux données à
                                caractère personnel, Ténéo Industrie s’engage à minimiser les informations divulguées à ses
                                sous-traitants à ce qui est strictement nécessaire pour la réalisation de l’objectif
                                poursuivi.
                            </p>
                            <p>
                                Ténéo Industrie exige de ses prestataires qu’ils préservent la sécurité de vos données à
                                caractère personnel et respectent la règlementation applicable. Les prestataires de
                                Ténéo Industrie ne sont pas autorisés à utiliser vos données à caractère personnel pour
                                poursuivre d’autres fins que les fins spécifiées par Ténéo Industrie et ne peuvent les
                                utiliser que conformément à ses instructions.
                            </p>
                            <p>En tout état de cause, vos données à caractère personnel ne sont en aucun cas
                                communiquées à aucune autre personne que celles mentionnées ci-dessus. Elles ne sont, en
                                outre, ni échangées, ni vendues ni louées à des tiers.</p>
                            <p>En outre, et afin de se conformer à des obligations légales, réglementaires, judiciaires
                                ou administratives ou de répondre à des demandes des autorités administratives et
                                judiciaires, Ténéo Industrie peut être amené à communiquer vos données à ces autorités à
                                chaque fois qu’il est juridiquement tenu de le faire.<strong>&nbsp;</strong></p>
                            <p><strong>7. Cookies</strong></p>
<!--                            <p>Enfin, votre navigation sur le Site donne lieu au dépôt de&nbsp;cookies&nbsp;sur le-->
<!--                                terminal que vous utilisez.&nbsp;Ténéo Industrie vous invite à prendre connaissance de sa <a-->
<!--                                    href="https://www.a2p-avocat.eu/politique-de-cookies/">Politique de gestion des-->
<!--                                    cookies</a>.</p>-->
                            <p>
                                Enfin, votre navigation sur le Site donne lieu au dépôt
                                de&nbsp;cookies&nbsp;sur le terminal que vous utilisez.&nbsp; Par contre ceux-ci sont vide
                                et ne contiennent aucune informations quelconque a date.
                                Le jour ou Ténéo industrie fera usage des cookies nous publierons notre politique d'utilisation
                                des cookies et mettrons en place un moyen d'acceptation ou de refus desdits cookies.
                            </p>

                            <p>Pour en savoir plus sur les cookies (comment les gérer, les supprimer, les identifier),
                                vous pouvez également consulter le site de la CNIL : <a
                                    href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/#c5554">http://www.cnil.fr/vos-droits/vos-traces/les-</a>
                                <a href="http://www.cnil.fr/vos-droits/vos-traces/les-cookies/#c5554">cookies/#c5554</a>&nbsp;ainsi
                                que les <a
                                    href="https://www.cnil.fr/sites/default/files/atoms/files/ligne-directrice-cookies-et-autres-traceurs.pdf">lignes
                                    directrices</a> et les <a
                                    href="https://www.cnil.fr/sites/default/files/atoms/files/recommandation-cookies-et-autres-traceurs.pdf">recommandations</a>
                                de la CNIL.</p>
                            <p><strong>8. Durée de conservation des données</strong></p>
                            <p>Ténéo Industrie s’engage à ce que les données collectées soient conservées sous une forme
                                permettant votre identification pendant une durée qui n’excède pas la durée nécessaire
                                aux finalités pour lesquelles ces données sont collectées et traitées.</p>
<!--                            <p>Les données collectées lors de la prise de RDV en ligne sont conservées 6 (six) mois puis-->
<!--                                sont détruites.</p>-->
                            <p>Cependant, le traitement des données est possible pour la preuve d’un droit ou d’un
                                contrat. Ces données peuvent également être conservées dans l’objectif de respecter une
                                obligation légale ou gardées dans des fichiers conformément à la règlementation
                                applicable.</p>
                            <p>Les données à caractère personnel relatives à un Utilisateur peuvent être conservées
                                pendant un délai de 3 (trois) ans à compter de leur collecte par Ténéo Industrie ou du
                                dernier contact émanant de l’Utilisateur.</p>
                            <p>Pour la gestion des demandes de droit sur vos données à caractère personnel (voir infra
                                Article 9 – Droits).</p>
                            <p><strong>9. Droits</strong></p>
                            <p>Conformément à la Loi Informatique et Libertés modifiée et au RGPD, vous disposez des
                                droits suivants :</p>
                            <ul>
                                <li>Droit d’accès (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article15">article
                                    15 RGPD</a>) et de rectification (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article16">article
                                    16 RGPD</a>) de vos données (<a href="https://www.cnil.fr/fr/comprendre-vos-droits"><strong><u>en
                                    savoir plus</u></strong></a><strong>)</strong></li>
                                <li>Droit à l’effacement de vos données à caractère personnel (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article17">article
                                    17 RGPD</a>) lorsque, notamment, vos données à caractère personnel ne sont plus
                                    nécessaires au regard des finalités pour lesquelles elles ont été collectées ou
                                    traitées d’une autre manière (<strong><a
                                        href="https://www.cnil.fr/fr/le-droit-leffacement-supprimer-vos-donnees-en-ligne">en
                                        savoir plus</a></strong>)
                                </li>
                                <li>Droit de retirer à tout moment votre consentement (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article13">article
                                    13-2c RGPD</a>)
                                </li>
                                <li>Droit à la limitation du traitement de vos données (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article18">article
                                    18 RGPD</a>)
                                </li>
                                <li>Droit à la portabilité des données que vous avez fournies à Ténéo Industrie, lorsque vos
                                    données font l’objet de traitements automatisés fondés sur votre consentement ou sur
                                    un contrat (<a
                                        href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article20">article
                                        20 RGPD</a>)
                                </li>
                                <li>Droit d’opposition au traitement de vos données (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article21">article
                                    21 RGPD</a>) (<a href="https://www.cnil.fr/fr/comprendre-vos-droits"><strong><u>en
                                    savoir</u></strong></a><a
                                    href="https://www.cnil.fr/fr/comprendre-vos-droits"><strong><u>
                                    plus</u></strong></a>)
                                </li>
                                <li>Droit d’introduire une réclamation auprès de la CNIL (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre8#Article77">article
                                    77 RGPD</a>)
                                </li>
                                <li>Droit de définir le sort de vos données après votre mort et de choisir que Ténéo Industrie
                                    communique (ou non) vos données à un tiers que vous aurez préalablement
                                    désigné (<a
                                        href="https://www.cnil.fr/fr/ce-que-change-la-loi-pour-une-republique-numerique-pour-la-protection-des-donnees-personnelles#mortnumerique"><strong><u>en
                                        savoir plus</u></strong></a>). En cas de décès et à défaut d’instructions de
                                    votre part, Ténéo Industrie s’engage à détruire vos données, sauf si leur conservation
                                    s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.
                                </li>
                            </ul>
                            <p>Ces droits peuvent être exercés, par simple demande par courrier électronique à l’adresse
                                r g p d [ a t ] t e n e o - i n d u s t r i e . f r (sans espaces) ou par courrier à
                                Jonathan Hellé - Ceo de Ténéo Industrie &amp; 24 RUE DU BEL AIR 91540 MENNECY &nbsp;
                                en indiquant vos coordonnées (nom,
                                prénom, adresse ainsi d’une copie d’une pièce d’identité en cours de validité et ce,
                                quel que soit le mode d’exercice de vos droits) et un motif légitime lorsque celui-ci
                                est exigé par la loi (notamment en cas d’opposition au traitement).</p>
                            <p>En cas de communication de copie de pièce d’identité pour justifier de votre identité,
                                Ténéo Industrie la conservera un (1) an ou trois (3) ans lorsque cette communication est
                                effectuée dans le cadre de l’exercice d’un droit d’opposition.</p>
                            <p>Pour rappel, lorsque la base légale du traitement est une obligation légale, le droit
                                d’opposition et le droit à la portabilité ne peuvent s’exercer. Par ailleurs, le droit à
                                la portabilité ne peut s’exercer à l’égard des traitements fondés sur l’intérêt
                                légitimes. Enfin, le droit d’opposition ne peut s’exercer lorsque la base légale du
                                traitement est le contrat.</p>
                            <p>Pour mieux connaître vos droits, vous pouvez également consulter le site de la Commission
                                Nationale de l’Informatique et des Libertés (CNIL), accessible à l’adresse <a
                                    href="https://www.cnil.fr/">https://www.cnil.fr/</a>.</p>
                            <p><strong>10. Droit d’introduire une réclamation auprès de la CNIL</strong></p>
                            <p>En cas de difficulté en lien avec la gestion de vos données à caractère personnel par Ténéo Industrie,
                                vous avez la possibilité d’introduire une réclamation auprès de la CNIL
                                (Commission Nationale de l’Informatique et des Libertés), autorité de contrôle
                                française, soit, via l’URL <a href="https://www.cnil.fr/fr/plaintes">https://www.cnil.fr/fr/plaintes</a>
                                soit, par courrier postal (3, Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07).</p>
                            <p><strong>11. Coordonnées du référent à la protection des données</strong></p>
                            <p>Pour toute demande d’information concernant la Politique de confidentialité du Site, vous
                                pouvez vous adresser au référent à la Protection des Données de Ténéo Industrie qui est là pour
                                répondre à toutes vos demandes, y compris d’exercice de droits relatifs à vos données à
                                caractère personnel.</p>
                            <p>Vous pouvez le joindre à l’adresse électronique &nbsp;r g p d [ a t ] t e n e o - i n d u s t r i e . f r (sans espaces)
                                ou par courrier à Jonathan Hellé - Ceo de Ténéo Industrie &amp; 24 RUE DU BEL AIR 91540 MENNECY</p>
                            <p><strong>12. Sécurité</strong></p>
                            <p>Ténéo Industrie, et ses éventuels sous-traitants, s’engagent à mettre en œuvre toutes les
                                mesures techniques et organisationnelles afin d’assurer la sécurité de leurs traitements
                                de données à caractère personnel et la confidentialité de vos données, en application de
                                la Loi informatique et Libertés modifiée et/ou du Règlement européen sur la protection
                                des données (<a
                                    href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre4#Article32">Article
                                    32 du RGPD</a>).</p>
                            <p>A ce titre, Ténéo Industrie prend les précautions utiles, au regard de la nature de vos
                                données et des risques présentés par ses traitements, pour préserver la sécurité des
                                données et, notamment, empêcher qu’elles soient déformées, endommagées, ou que des tiers
                                non autorisés y aient accès.</p>
                            <p><strong>13. Transferts hors UE</strong></p>
                            <p>Ténéo Industrie s’engage à ne pas transférer les données des Utilisateurs en dehors de l’Union
                                Européenne.</p>
                            <p>Dans l’hypothèse où Ténéo Industrie serait amené à le faire, il en informerait les
                                Utilisateurs en indiquant les mesures prises afin de contrôler ce transfert et s’assurer
                                du respect de la confidentialité de leurs données.</p>
                            <p align="justify"><strong>Crédits</strong>&nbsp;:</p>
                            <p>• Cookies : les outils pour les maîtriser</p>
                            <p><span
                                style="font-family: 'Open Sans', sans-serif; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400;">• Cookies : lignes directrices et recommandations de la CNIL</span>
                            </p>
                            <p>• Les droits pour maîtriser vos données personnelles !</p>
                            <p>• Le droit à l’effacement : supprimer vos données en ligne</p>
                            <p>• Le droit d’opposition : refuser l’utilisation de vos données</p>
                            <p>• Ce que change la loi pour une République numérique pour la protection des données
                                personnelles</p>
                            <p align="justify">«&nbsp;Source&nbsp;:&nbsp;CNIL –&nbsp;<a href="https://www.cnil.fr/fr">http://www.cnil.fr</a>&nbsp;»
                            </p>
                            <p align="justify">22.02.2025</p>
                            <p align="justify"><img decoding="async"
                                                    src="https://www.zeetona.com/wp-content/uploads/2020/07/cc-by-nd_3-e1594283511198.png"
                                                    alt="" width="100" height="35">&nbsp;Allée</p></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



