import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { Meta } from '@angular/platform-browser';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router,
        private gtmService: GoogleTagManagerService,
        private meta: Meta
    ) {
    }

    ngOnInit(){
        // meta on homepage
        this.meta.addTag({ name: 'title', content: 'Ténéo Industrie - Société de Maintenance Industrielle et de crématoriums' });
        this.meta.addTag({ name: 'og:title', content: 'Ténéo Industrie - Société de Maintenance Industrielle et de crématoriums' });
        this.meta.addTag({ name: 'description', content: 'Ténéo Industrie est une société de maintenance industrielle reconnue pour son savoir-faire et son expérience aussi bien sur des sites indutrielles que sur des crématoriums en france et en europe' });
        this.meta.addTag({ name: 'og:locale', content: 'fr_FR' });
        this.meta.addTag({ name: 'og:type', content: 'website' });
        this.meta.addTag({ name: 'og:url', content: 'https://www.teneo-industrie.fr/' });
        this.meta.addTag({ name: 'og:description', content: 'Ténéo Industrie est une société de maintenance industrielle reconnue pour son savoir-faire et son expérience aussi bien sur des sites indutrielles que sur des crématoriums' });
        this.meta.addTag({ name: 'og:site_name', content: 'Société Ténéo Industrie' });
        this.meta.addTag({ name: 'keywords', content: 'maintenance, maintenance industrielle, maintenance crématorium, mécanique, automatisme, élétricité, electrotechnique, hydrolique, pneumatique, informatique, vidéo surveillance, réseau, gaz, traçabilité, gmao' });

        // analitics
        this.gtmService.pushTag({
            event: 'page_view',
            pagePath: 'page-principal'
        });
        // this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}
