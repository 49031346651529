<div class="page-title-area-mentions item-bg-maint2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h1 style="color: white">Mentions Légales </h1>
                    <p style="color: white">[www.teneo-industrie.fr]</p>
                   <br>
                    <ul>
                        <li><a routerLink="/">ACCUEIL</a></li>
                        <li><i class="fas fa-chevron-right"></i></li>
                        <li class="active">MENTIONS</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area ptb-100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-text">

                        <h2>1. Présentation du site</h2>
                        <p><strong>Propriétaire :</strong><br>
                            <strong>Nom de la société :</strong> Téneo-Industrie SAS<br>
                            <strong>Siren :</strong> 984790287<br>
                            <strong>Adresse du siège social :</strong> 24 RUE DU BEL AIR 91540 MENNECY<br>
<!--                            <strong>Numéro de téléphone :</strong> [Votre numéro de téléphone]<br>-->
<!--                            <strong>Adresse email :</strong> &nbsp; <a href="mailto:contact@teneo-industrie.fr">contact&#64;teneo-industrie.fr</a></p>-->

<!--                        <p><strong>Directeur de la publication :</strong><br>-->
<!--                            [Nom du directeur de la publication]</p>-->

<!--                        <p><strong>Hébergement :</strong><br>-->
<!--                            <strong>Hébergeur :</strong> &nbsp; <a href="https://www.ovhcloud.com/fr/">OVH</a> <br>-->
<!--                            <strong>Adresse de l'hébergeur :</strong> 2 rue Kellermann - 59100 Roubaix - France<br>-->
<!--                        </p>-->

                        <h2>2. Conditions générales d'utilisation du site et des services proposés</h2>
                        <p>L’utilisation du site [www.teneo-industrie.fr] implique l’acceptation pleine et entière des
                            conditions générales
                            d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être
                            modifiées ou complétées à
                            tout moment, les utilisateurs du site [www.teneo-industrie.fr] sont donc invités à les
                            consulter de manière
                            régulière.</p>

                        <h2>3. Description des services fournis</h2>
                        <p>Le site internet [www.teneo-industrie.fr] a pour objet de fournir une information concernant
                            l’ensemble des activités
                            de
                            la société. Téneo-Industrie s’efforce de fournir sur le site [www.teneo-industrie.fr] des
                            informations aussi précises
                            que
                            possible. Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et des
                            carences dans la
                            mise à
                            jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces
                            informations.</p>

                        <h2>4. Propriété intellectuelle et contrefaçons</h2>
                        <p>Téneo-Industrie est propriétaire des droits de propriété intellectuelle ou détient les droits
                            d’usage sur tous
                            les
                            éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes,
                            sons, logiciels. Toute
                            reproduction, représentation, modification, publication, adaptation de tout ou partie des
                            éléments du site, quel
                            que
                            soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de :
                            Téneo-Industrie.</p>

                        <h2>5. Limitations de responsabilité</h2>
                        <p>Téneo-Industrie ne pourra être tenu responsable des dommages directs et indirects causés au
                            matériel de
                            l’utilisateur, lors de l’accès au site [www.teneo-industrie.fr], et résultant soit de
                            l’utilisation d’un matériel ne
                            répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou
                            d’une
                            incompatibilité.</p>

                        <h2>6. Politique de confidentialité</h2>
                        <p>
                            elle est disponible en cliquant <a [routerLink]="['/politique-de-confidentialite']">ici</a>
                        </p>

                        <h2>7. Liens hypertextes et cookies</h2>
                        <p>Le site [www.teneo-industrie.fr] contient un certain nombre de liens hypertextes vers
                            d’autres sites, mis en place
                            avec
                            l’autorisation de Téneo-Industrie. Cependant, Téneo-Industrie n’a pas la possibilité de
                            vérifier le contenu des
                            sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.</p>
                        <p>La navigation sur le site [www.teneo-industrie.fr] est susceptible de provoquer
                            l’installation de cookie(s) sur
                            l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas
                            l’identification de
                            l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un
                            ordinateur sur un site. Les
                            données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont
                            également vocation à
                            permettre diverses mesures de fréquentation.</p>
                        <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains
                            services. L’utilisateur
                            peut
                            toutefois configurer son ordinateur de la manière suivante, pour refuser l’installation des
                            cookies :</p>
                        <ul>
                            <li>Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut à droite)
                                / options internet.
                                Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. Validez sur Ok.
                            </li>
                            <li>Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis
                                aller dans l'onglet
                                Options. Cliquer sur l'onglet Vie privée et Sécurité. Paramétrez les Règles de
                                conservation sur : utiliser
                                les
                                paramètres personnalisés pour l'historique. Enfin décochez-la pour désactiver les
                                cookies.
                            </li>
                            <li>Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu
                                (symbolisé par un rouage).
                                Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. Dans la section
                                "Confidentialité",
                                cliquez
                                sur Paramètres de contenu. Dans la section "Cookies", vous pouvez bloquer les cookies.
                            </li>
                            <li>Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu
                                (symbolisé par trois lignes
                                horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés.
                                Dans la section
                                "Confidentialité", cliquez sur préférences. Dans l'onglet "Confidentialité", vous pouvez
                                bloquer les
                                cookies.
                            </li>
                        </ul>

                        <h2>8. Droit applicable et attribution de juridiction</h2>
                        <p>Tout litige en relation avec l’utilisation du site [www.teneo-industrie.fr] est soumis au
                            droit français. Il est fait
                            attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>

                        <h2>9. Les principales lois concernées</h2>
                        <ul>
                            <li>Loi n° 78-17 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004
                                relative à
                                l'informatique, aux fichiers et aux libertés.
                            </li>
                            <li>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</li>
                        </ul>

                        <h2>10. Lexique</h2>
                        <p><strong>Utilisateur :</strong> Internaute se connectant, utilisant le site susnommé.<br>
                            <strong>Informations personnelles :</strong> « les informations qui permettent, sous quelque
                            forme que ce soit,
                            directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent »
                            (article 4 de la
                            loi n°
                            78-17 du 6 janvier 1978).</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



