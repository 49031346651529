<div class="cta-area ptb-100">
    <a href="https://www.funexpo-expo.com/fr/le-salon-du-funeraire">
        <img src="assets/img/salon3.jpg"  alt="about">
    </a>
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
<!--                <h4>Looking for exclusive services?</h4>-->
<!--                <h2>Venez nous rencontrez stand 80</h2>-->
<!--                <p>nous serons présent au Salon Funexpo à Lyon du 21 au 23 nov au stand 80</p>-->
<!--                <a (click)="onClick('contact')" class="btn btn-primary">Nous seront au stand 80</a>-->
<!--                <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>-->
            </div>
        </div>
    </div>
</div>
