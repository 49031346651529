import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-cookie',
  templateUrl: './cookie.component.html',
  styleUrl: './cookie.component.scss'
})
export class CookieComponent implements OnInit {
    ngOnInit() {
    }
}
