<footer class="footer-area">
    <div class="container">
        <img src="assets/img/logblanc.png" style="width: 500px" alt="about">
<!--        <h3><a routerLink="/"><span>T</span>énéo <span>I</span>ndustrie</a></h3>-->
<!--        <ul>-->
<!--            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>-->
<!--            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>-->
<!--        </ul>-->
        <p>© Ténéo Industrie </p>
        <br>
        <a style="color: #ffffff;" [routerLink]="['/mentions']">Mentions légales</a>
        <br>
        <a style="color: #ffffff;" [routerLink]="['/politique-de-confidentialite']">Politique de confidentialité</a>
<!--        <br>-->
<!--        <a [routerLink]="['/politique-de-gestion-des-cookies']">Politique de gestion des cookies</a>-->
    </div>
</footer>

<!--<app-demo-sidebar></app-demo-sidebar>-->

<ngx-scrolltop></ngx-scrolltop>
